import React from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
} from './SidebarElement';

const Sidebar = ({ isOpen, toggle }) => {
  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to='/' onClick={toggle}>
            Beranda
          </SidebarLink>
          <SidebarLink to='dokumen' onClick={toggle}>
            Dokumen
          </SidebarLink>
          <SidebarLink to='penerjemahan-lisan' onClick={toggle}>
            Lisan
          </SidebarLink>
          <SidebarLink to='legalisasi' onClick={toggle}>
            Legalisasi
          </SidebarLink>
          <SidebarLink to='contact' onClick={toggle}>
            Kontak
          </SidebarLink>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
