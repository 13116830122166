import './Contact.css';
import { FaEnvelope, FaMapMarkerAlt, FaPhone } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const Contact = () => {
  function sendEmail(e) {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_39dkeop',
        'template_y5gsl9v',
        e.target,
        'user_15eSZVQLBwa4Xs8wMgguH'
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <div className='contact' id='contact'>
        <h1>Kontak Kami</h1>
        <div className='container-2'>
          <div className='contactInfo'>
            <div className='box'>
              <div className='icon'>
                <FaMapMarkerAlt />
              </div>
              <div className='text'>
                <h3>Alamat</h3>
                <p>
                  Jl. Cirendeu Indah II No. 49 <br /> Tangerang Selatan 15419
                </p>
              </div>
            </div>
            <div className='box'>
              <div className='icon'>
                {' '}
                <FaPhone />{' '}
              </div>
              <div className='text'>
                <h3>Telepon</h3>
                <p> +62 877 3076 4439 </p>
              </div>
            </div>
            <div className='box'>
              <div className='icon'>
                {' '}
                <FaEnvelope />{' '}
              </div>
              <div className='text'>
                <h3>Email</h3>
                <p>infopenerjemah@gmail.com</p>
              </div>
            </div>
          </div>
          <div className='contactForm'>
            <form onSubmit={sendEmail}>
              <h2>Kirim Pesan</h2>
              <div className='inputBox'>
                <input type='text' name='name' required />
                <span>Nama Lengkap</span>
              </div>
              <div className='inputBox'>
                <input type='text' name='email' required />
                <span>Email</span>
              </div>
              <div className='inputBox'>
                <textarea name='message' required />
                <span>Tulis Pesan Anda ...</span>
              </div>
              <div className='inputBox'>
                <input type='submit' name='Submit' value='Kirim' />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
