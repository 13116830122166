import React from 'react';
import './Legalisasi.css';

const Legalisasi = () => {
  return (
    <>
      <div id='legalisasi'>
        <div className='container-11'>
          <div id='main-col'>
            <ul id='jasa-legalisasi'>
              <li>
                <h1>Legalisasi Dokumen</h1>
                <p>
                  Kami menyediakan jasa legalisasi dokumen yang akan membantu
                  anda mendapatkan legalisir dokumen dari Departemen Hukum dan
                  HAM dan Departemen Luar Negeri dengan mudah. Anda tinggal
                  menghubungi kami dan kami akan mengurus segala prosesnya untuk
                  anda.
                </p>
                <p>
                  Adapun jenis dokumen yang dilegalisir antara lain: Ijazah,
                  transkrip nilai, akta pendirian, surat perjanjian, akta nikah,
                  akta cerai dll.
                </p>
                <p>
                  Sebagai tambahan Informasi, persyaratan yang dibutuhkan untuk
                  proses legalisasi di Departemen Hukum dan HAM dan Departemen
                  Luar Negeri antara lain:
                </p>
                <p>1. Surat Permohonan</p>
                <p>2. Surat Kuasa</p>
                <p>3. Poto Kopi KTP</p>
                <p>4. Poto Kopi Dokumen</p>
                <p>5. Materai 6000 per lembar</p>
                <br />
                <p>Adapun Standar Biaya Jasa Legalisasi / Legalisir:</p>
                <table>
                  <tr>
                    <th>Paket Express</th>
                    <th>Lama Proses</th>
                  </tr>
                  <tr>
                    <td>Rp 500.000,-</td>
                    <td>3 hari kerja</td>
                  </tr>
                </table>
                <br />
                <p>Adapun Standar Biaya Jasa Legalisasi / Notaris:</p>
                <table>
                  <tr>
                    <th>Paket Harga </th>
                    <th>Lama Proses</th>
                  </tr>
                  <tr>
                    <td>Rp 250.000,-</td>
                    <td>2 hari kerja</td>
                  </tr>
                </table>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Legalisasi;
