import './Banner.css';

const Banner = () => {
  return (
    <>
      <section className='xbox' id='banner'>
        <div className='content'>
          <h2>Layanan dan Kualitas Terbaik</h2>
          <p>
            Kami telah berpengalaman dalam menyediakan jasa penerjemahan dengan
            ribuan pelanggan yang tersebar di berbagai daerah di Indonesia.
          </p>
        </div>
      </section>
    </>
  );
};

export default Banner;
